import moment from 'moment'

export default {
    data() {
        return {
            metodo_valid: true,
            orario_valid: true,
            dialog_confirm: false,
            on_ending: false,
            ordine_confermato: null
        }
    },
    methods: {
        concludi_ordine() {
            this.$refs.metodo_form.validate()
            if (!this.metodo_valid) {
                return
            }
            let time_stamp = 0
            if (this.metodo_trasporto == 0) {
                this.$refs.orario_form.validate()
                if (!this.orario_valid) {
                    return
                }
                time_stamp = moment(this.date + ' ' + this.time).format("X")
            }
            this.on_ending = true
            let json_ordine = {
                'commento': this.commento,
                'consegna': this.metodo_trasporto,
                'tipo_cc': 0,
                'destinazione': this.destinazione_sel,
                'giorno': this.dateFormatter,
                'orario': this.time,
                'orario_ts': time_stamp,
                'notifica': false,
            };
            this.$store.dispatch("concludi_ordine", json_ordine)
                .then((res) => {
                    this.ordine_confermato = res
                    this.dialog_confirm = true
                })
                .finally(() => {
                    this.on_ending = false
                })
        }
    }
}