import store from '@/store/index'
import {mapGetters} from 'vuex'
export default {
    props: {
        item: Object
    },
    data() {
        return {
            metodo_trasporto: '1',
            commento: '',
            destinazione_sel: this.$store.getters.get_utente.ultima_destinazione
        }
    },
    computed: {
        ...mapGetters({
            trasporto: 'get_trasporto',
            utente: 'get_utente'
        }),
        tot_cc() {
            if (!this.carrello.length) {
                    return 0
                }
            return parseFloat(
                this.carrello
                    .map(item => {
                        let prodotto = store.getters.get_prodotto_by_id(item.art)
                        if (!prodotto) {
                            return 0
                        }
                        let qta_cc = prodotto.qta_cc
                        if (!qta_cc) {
                            return 0
                        } else {
                            return (item.colori
                                .map((col) => parseInt(col.numero))
                                .reduce((tot, num) => tot + num)) / qta_cc
                        }
                    })
                    .reduce((tot, num) => tot + num).toFixed(1)
            );
        },
        tot_merce() {
            return parseFloat(
                this.carrello
                    .map((item) =>
                        item.colori
                            .map((col) => parseInt(col.numero) * item.prezzo)
                            .reduce((tot, num) => tot + num)
                    )
                    .reduce((tot, num) => tot + num)
            );
        },
        cart_tot() {
            if (this.carrello.length > 0) {
                return (this.tot_merce + this.tot_trasp).toFixed(2);
            } else {
                return 0;
            }
        },
        tot_trasp() {
            if (this.metodo_trasporto == 1 && this.tot_merce < this.trasporto.soglia) {
                return this.trasporto.spesa;
            } else {
                return 0;
            }
        },
        trasp_label() {
            if (this.trasporto.spesa > 0) {
                return (
                    "Trasporto gratuito per ordini superiori ai " +
                    this.trasporto.soglia +
                    " €"
                );
            } else {
                return "Trasporto gratuito per ogni cifra";
            }
        },
    }
}