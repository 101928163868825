//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import printer from "@/service/printer";
// import NuovoMessaggio from '../messaggi/mobile/nuova_conversazione_mobile'
import Chat from "../chat/desktop/chat_main_desktop.vue";
import ChatMobile from "../chat/mobile/chat_main_mobile.vue";
export default {
  components: {
    // NuovoMessaggio,
    Chat,
    ChatMobile,
  },
  props: {
    show: Boolean,
    ordine: Object,
  },
  data() {
    return {
      show_nuovo_msg: false,
    };
  },
  methods: {
    apri_msg() {
      this.$refs.new_conv.open();
    },
    pdf() {
      printer.print_pdf(this.ordine._id.$oid);
    },
  },
};
