//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import chat_main_mixin from "../mixin/chat_main_mixin";
import Messaggio from "./chat_messaggio";
import EmojiPicker from "../emoji_picket";
import ImagePicker from "../image_picker";
import FilePicker from "../file_picker";
export default {
  components: {
    Messaggio,
    EmojiPicker,
    ImagePicker,
    FilePicker,
  },
  props: {
    show: Boolean,
    icon: Boolean,
  },
  data() {
    return {
      innerHeight: window.innerHeight,
      //menu: false,
    };
  },
  created() {
    addEventListener("resize", () => {
      this.innerHeight = window.innerHeight;
    });
  },
  watch: {
    dialog: function(){
      console.log("watch dialog")
      if(!this.dialog){
        if(this.$route.hash == '#dettaglio-chat'){
          console.log("back")
          this.$router.back()
        }
      }
    }
    // menu: function () {
    //   if (!this.menu) {
    //     this.$emit("close");
    //   }
    // },
    // show: function () {
    //   this.menu = this.show;
    // },
  },
  computed: {
    body_height() {
      return this.innerHeight - 190;
    },
  },
  mixins: [chat_main_mixin],
};
