//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    chat_id: String,
    autore: String,
    destinatario: String
  },
  data() {
    return {
      main_loading: false,
      percentLoadMain: 0,
      random_date: 0,
    };
  },
  computed: {
    file_load_percent() {
      return this.$store.getters.get_file_load_percent;
    },
  },
  methods: {
    open_file_window() {
      this.$refs.input.click();
    },
    upload_img: function (e) {
      let file = e.target.files[0];
      if (!file) {
        return;
      }
      let formData = new FormData();
      formData.append("file", file);
      this.$store.dispatch("send_chat_file", {
        id_chat: this.chat_id,
        destinatario: this.destinatario,
        formData: formData,
      });
    },
  },
};
