//
//
//
//
//
//
//
//
//

export default {
    props: {
        foto: Object,
        prodotto: String
    },
    data(){
        return{
            dialog: false
        }
    },
    computed:{
        img_url(){
            return process.env.VUE_APP_API_ROOT + "/prodotto/" + this.prodotto + "/foto/" + this.foto.id + "?t=" + this.foto.md5
        }
    }
}
