//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      show_emo: false,
      emoji: null,
      filter: "Smileys",
    };
  },
  created() {
    this.emoji = require("emoji.json");
  },
  computed: {
    filtered() {
      if (this.filter == "People") {
        return this.emoji
          .filter((emo) => {
            return emo.group.includes(this.filter);
          })
          .filter((emo) => {
            return !emo.name.includes("skin");
          });
      }
      return this.emoji.filter((emo) => {
        return emo.group.includes(this.filter);
      });
    },
  },
  methods: {
    select(char) {
      this.$emit("emoji",char);
      this.show_emo = false
    },
  },
};
