import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      date: this.minDate,
      time: null,
      modal_date: false,
      modal_time: false,
      date_picker: 0,
      sheet: false,
    };
  },
  mounted() {
    moment.locale("it");
  },
  watch: {
    tot_cc: function () {
      this.date = "";
      this.time = null;
    },
    date: function () {
      this.time = null;
    },
  },
  computed: {
    ...mapGetters({
      soglie: "get_soglie_orari",
    }),
    dateFormatter() {
      return this.date ? moment(this.date).format("ddd D MMM") : "";
    },
    h_soglia() {
      switch (Math.floor(this.tot_cc)) {
        case 0:
          return this.soglie.soglia_1;
        case 1:
          return this.soglie.soglia_2;
        case 2:
          return this.soglie.soglia_3;
        default:
          return this.soglie.soglia_4;
      }
    },
    minuti_soglia() {
      let minuti = this.tot_cc * this.soglie.t_prep_cc;
      if (minuti < 60) {
        return 60;
      } else {
        return minuti;
      }
    },
    minDate() {
      if (
        moment()
          .add(this.minuti_soglia, "minutes")
          .isAfter(moment().hour(this.soglie.chiusura))
      ) {
        return moment().add(1, "day").format("YYYY-MM-DD");
      } else {
        return moment().format("YYYY-MM-DD");
      }
    },
    maxDate() {
      return moment().add(7, "days").format("YYYY-MM-DD");
    },

    minHour() {
      if (moment(this.date).isSame(moment(), "day")) {
        return moment().add(this.minuti_soglia, "minutes").format("HH");
      }
      if (moment(this.date).isSame(moment().add(1, "day"), "day")) {
        let chiusura = moment().hour(this.soglie.chiusura);
        let t_left =
          this.minuti_soglia -
          moment.duration(chiusura.diff(moment())).asMinutes();
        return moment()
          .add(1, "day")
          .hour(this.soglie.apertura)
          .add(t_left, "minutes")
          .format("HH");
      }
      if (moment(this.date).isAfter(moment().add(1, "days"))) {
        return this.soglie.apertura.toString();
      }
    },

    prima_data() {
      let giorno;
      let ora;
      if (
        moment()
          .add(this.minuti_soglia, "minutes")
          .isAfter(moment().hour(this.soglie.chiusura))
      ) {
        giorno = moment().add(1, "day");
        let chiusura = moment().hour(this.soglie.chiusura);
        let t_left =
          this.minuti_soglia -
          moment.duration(chiusura.diff(moment())).asMinutes();
        ora = moment()
          .add(1, "day")
          .hour(this.soglie.apertura)
          .add(t_left, "minutes");
      } else {
        giorno = moment();
        ora = moment().add(this.minuti_soglia, "minutes");
      }
      if (giorno.isoWeekday() == 7) {
        giorno.add(1, "day");
      }
      return {
        giorno: giorno.format("dddd DD MMMM"),
        ora:
          ora.format("HH") != 13
            ? ora.format("HH")
            : ora.add(1, "h").format("HH"),
        formatted: giorno.format("YYYY-MM-DD"),
      };
    },
    valid_day() {
      return !(this.date != null && this.date != "");
    },
  },
  methods: {
    allowedDate: (d) => parseInt(moment(d).isoWeekday()) != 7,
    closePiker: function (v) {
      v = v < 10 ? "0" + v : v;
      this.time = v + ":00";
      this.modal_time = false;
    },
    set_prima_data() {
      this.date = this.prima_data.formatted;
      setTimeout(() => {
        this.time = this.prima_data.ora + ":00";
      }, 100);
    },
  },
};
