//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import chat_main_mixin from "../mixin/chat_main_mixin";
import Messaggio from "../desktop/chat_messaggio";
import EmojiPicker from "./emoji_picker_mobile";
import ImagePicker from "../image_picker";
import FilePicker from "../file_picker";
export default {
  components: {
    Messaggio,
    EmojiPicker,
    ImagePicker,
    FilePicker,
  },
  props: {
    show: Boolean,
    icon: Boolean,
  },
  data() {
    return {
      //menu: false,
    };
  },
  watch: {
    // menu: function () {
    //   if (!this.menu) {
    //     if (this.$route.hash == "#chat") {
    //       this.$router.back();
    //     }
    //     this.$emit("close");
    //   } else {
    //     this.$router.push("#chat");
    //   }
    // },
    // "$route.hash": function (to, from) {
    //   if (from == "#chat") {
    //     this.menu = false;
    //   }
    // },
    // show: function () {
    //   this.menu = this.show;
    // },
  },
  mixins: [chat_main_mixin],
  methods: {
    // open() {
    //   this.menu = true;
    // },
    scroll() {
      setTimeout(() => {
        this.scroll_down();
      }, 300);
    },
  },
};
