//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      show_emo: false,
      emoji: null,
      filter: "Smileys",
      tab: null,
      tabs: [
        {
          icon: "mdi-emoticon-happy-outline",
          filter: "Smileys",
        },
        {
          icon: "mdi-account-outline",
          filter: "People",
        },
        {
          icon: "mdi-lightbulb-outline",
          filter: "Object",
        },
        {
          icon: "mdi-flower",
          filter: "Nature",
        },
      ],
    };
  },
  created() {
    this.emoji = require("emoji.json");
  },
  computed: {
    filtered() {
      if (this.filter == "People") {
        return this.emoji
          .filter((emo) => {
            return emo.group.includes(this.filter);
          })
          .filter((emo) => {
            return !emo.name.includes("skin");
          });
      }
      return this.emoji.filter((emo) => {
        return emo.group.includes(this.filter);
      });
    },
  },
  methods: {
    filter_emo(key) {
      if (key == "People") {
        return this.emoji
          .filter((emo) => {
            return emo.group.includes(key);
          })
          .filter((emo) => {
            return !emo.name.includes("skin");
          });
      }
      return this.emoji.filter((emo) => {
        return emo.group.includes(key);
      });
    },
    select(char) {
      this.$emit("emoji", char);
      this.show_emo = false;
    },
  },
};
