import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";

export default {
  data() {
    return {
      dialog: false,
      text_tosend: "",
      typing: false,
      change_timeout: undefined,
      show_emo: false,
      loading_create: false,
    };
  },
  created() {
    console.log("[CHAT_MAIN_MIXIN] Add event listener");
    addEventListener("chat_reply", (e) => {
      console.log("[CHAT_MAIN_MIXIN] Chat Reply from sw");
      this.text_tosend = e.data;
      this.send_msg();
    });
  },
  watch: {
    $route(to, from) {
      if (from.hash === "#dettaglio-chat" && this.dialog) {
        this.dialog = false;
      }
    },
    dialog: function () {
      if (this.dialog && this.chat != null) {
        this.$store.dispatch("find_non_letto_index");
        setTimeout(() => {
          this.scroll_down();
        }, 500);
      }
    },
    chat: {
      deep: true,
      handler() {
        if (this.dialog) {
          setTimeout(() => {
            this.scroll_down();
          }, 100);
        }
      },
    },
    text_tosend: function () {
      if (!this.chat.destinatario) {
        return;
      }
      if (!this.typing) {
        this.typing = true;
        this.$socket.emit("typing", {
          user: this.$store.getters.get_utente.iva,
          chat_id: this.chat._id,
          typing: true,
        });
      }
      if (this.change_timeout != undefined) {
        clearTimeout(this.change_timeout);
      }
      this.change_timeout = setTimeout(this.end_typing, 1000);
    },
  },
  computed: {
    ...mapGetters({
      chat: "get_chat",
      loading: "get_chat_loading",
      n_non_letti: "get_chat_nl",
      ontyping: "get_chat_typing",
      file_load_percent: "get_file_load_percent",
      img_load_percent: "get_img_load_percent",
      chat_archived: "get_chat_archived",
    }),
    last_access() {
      return moment.unix(this.chat.destinatario.last_access).calendar();
    },
    msg_array() {
      if (this.chat.messaggi == undefined) {
        return [];
      }
      let res = _.groupBy(this.chat.messaggi, (msg) => {
        return moment.unix(msg.ts_creazione).startOf("day");
      });
      let arrbyday = Object.keys(res).map((date) => {
        return {
          data: moment(date).calendar({
            sameDay: "[Oggi]",
            nextDay: "[Domani]",
            nextWeek: "dddd",
            lastDay: "[Ieri]",
            lastWeek: "[Lo Scorso] dddd",
            sameElse: "DD/MM/YYYY",
          }),
          msg: res[date],
        };
      });
      return arrbyday;
    },
  },
  methods: {
    crea_chat() {
      this.loading_create = true;
      this.$store.dispatch("create_chat").then(() => {
        this.loading_create = false;
      });
    },
    send_msg() {
      if (this.text_tosend == "") {
        return;
      }
      this.$store.dispatch("send_chat_msg", {
        id_conversazione: this.chat._id,
        destinatario: this.chat.destinatario
          ? this.chat.destinatario.iva
          : null,
        body: this.text_tosend,
      });
      this.text_tosend = "";
    },
    scroll_down() {
      console.log("scroll down");
      this.$refs.chat_body.scroll({ top: 9999 });
    },
    end_typing() {
      this.typing = false;
      this.$socket.emit("typing", {
        user: this.$store.getters.get_utente.iva,
        chat_id: this.chat._id,
        typing: false,
      });
    },
    add_to_text: function (char) {
      this.text_tosend = this.text_tosend + char;
    },
    open() {
      this.dialog = true;
      this.$router.push("#dettaglio-chat");
    },
    close() {
      this.$router.back();
      setTimeout(() => {
        this.dialog = false;
      }, 100);
    },
  },
};
