export default {
    props: {
        item: Object,
    },
    data() {
        return {
            dialog_elimina: false,
            show_color: false,
            show_prod: false,
            menu_azioni: false
        };
    },
    computed: {
        avatar_url() {
            return process.env.VUE_APP_API_ROOT + "/avatar_img/" + this.item.art ;
          },
        nome() {
            if (this.item.nome.includes("Vaso")) {
                return this.item.nome.split("Vaso")[0];
            } else {
                return this.item.nome.split("Basket")[0];
            }
        },
        tipo_vaso() {
            if (this.item.unita == "1") {
                return "Vaso Singolo";
            } else {
                return "Cassa da " + this.item.unita;
            }
        },
        prodotto() {
            return this.$store.getters.get_prodotto_by_id(this.item.art);
        },
        tot_pezzi() {
            return this.item.colori
                .map((col) => parseInt(col.numero))
                .reduce((tot, num) => tot + num);
        },
        prezzo_tot() {
            let tot = this.tot_pezzi * this.item.prezzo;
            return tot.toFixed(2);
        },
    },
    methods: {
        colore_url(id){
            return process.env.VUE_APP_API_ROOT + "/prodotto/" + this.item.art + "/img_colore/" + id
        },
        delete_prod() {
            this.$store
                .dispatch("rimuovi_prodotto_carrello", this.item.art)
                .then(() => {
                    this.$emit("delete");
                    this.dialog_elimina = false;
                    this.menu_azioni = false
                })
                .catch((err) => {
                    if (err.response.status == 400) {
                        this.$router.push("/login");
                    }
                });
        },
        edit_prod() {
            this.$refs.dettaglio.open();
        },
    },
};