//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  props: {
    messaggio: Object,
    id_conversazione: String,
    chat_visibile: Boolean,
  },
  created() {
    // if (
    //   this.chat_visibile &&
    //   !this.messaggio.letto &&
    //   this.messaggio.autore != this.$store.getters.get_utente.iva
    // ) {
    //   this.$store.dispatch("send_read_confirm_chat", {
    //     id_chat: this.id_conversazione,
    //     id_messaggio: this.messaggio._id,
    //   });
    // }
  },
  data() {
    return {
      dialog: false,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      img_loaded: "set_msg_img_load",
    }),
    mioMsg() {
      return this.messaggio.autore == this.$store.getters.get_utente.iva;
    },
    ora() {
      return moment.unix(this.messaggio.ts_creazione).format("HH:mm");
    },
    img_height() {
      return (300 * this.messaggio.height) / this.messaggio.width;
    },
    loneEmoji() {
      if (!this.messaggio.isText) {
        return false;
      }
      if (this.messaggio.testo.length == 2) {
        const regexExp = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
        let res = regexExp.test(this.messaggio.testo);
        return res;
      }
      return false;
    },
    type_icon() {
      switch (this.messaggio.file_type) {
        case "pdf":
          return {
            icon: "mdi-file-pdf-box",
            color: "red",
          };
        case "doc":
        case "docx":
          return {
            icon: "mdi-file-word-box",
            color: "blue",
          };
        case "xlsx":
          return {
            icon: "mdi-file-excel-box",
            color: "green",
          };
        default:
          return{
            icon: 'mdi-file',
            color: "blue"
          }
      }
    },
    thum_url(){
      return process.env.VUE_APP_API_ROOT + '/chat/thumb/' + this.messaggio._id
    },
    img_url(){
      return process.env.VUE_APP_API_ROOT + '/chat/foto/' + this.messaggio._id

    }
  },
  methods: {
    download() {
      let auth = this.$store.getters.get_credential;
      let url = process.env.VUE_APP_API_ROOT + '/chat/file/' + this.messaggio._id
      axios
        .get(url, { auth: auth, responseType: "blob" })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            this.messaggio.file_name + "." + this.messaggio.file_type
          );
          document.body.appendChild(fileLink);

          fileLink.click();
        });
    },
    visibilityChanged() {
      if (!this.chat_visibile) {
        return;
      }
      if (
        this.messaggio.letto != 2 &&
        this.messaggio.autore != this.$store.getters.get_utente.iva
      ) {
        this.$store.dispatch("send_read_confirm_chat", this.messaggio._id);
      }
    },
  },
};
