import _ from "lodash";

export default {
  props: {
    item: Object,
    show: Boolean,
  },
  data() {
    return {
      dialog: false,
      cmt_to_send: "",
      cmt_rules: [(v) => v.length <= 60 || "Max 60 caratteri"],
      help_menu: false,
    };
  },
  created() {
    this.debouncecmt = _.debounce(this.invia_cmt, 1200);
  },

  computed: {
    commento: {
      get() {
        let art = this.$store.getters.get_prodotto_carrello(this.item.id);
        return art ? art.commento : "";
      },
      set(cmt) {
        this.cmt_to_send = cmt;
        this.debouncecmt();
      },
    },
    avatar_url() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/avatar_img/" +
        this.item.id +
        "?t=" +
        this.item.avatar_md5
      );
    },
    main_url() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/main_img/" +
        this.item.id +
        "?t=" +
        this.item.img_md5
      );
    },
    foto_array() {
      let array = [];
      for (let i = 0; i < this.item.img_list_count; i++) {
        array.push(this.item.img_list_src + i);
      }
      return array;
    },

    unita() {
      if (this.item.unita == "1") {
        return "Vaso Singolo";
      } else {
        return "Cassa da " + this.item.unita;
      }
    },

    prezzo_unitario() {
      return (this.item.prezzo / this.item.unita).toFixed(2);
    },
    nome() {
      if (this.item.nome.includes("Vaso")) {
        return this.item.nome.split("Vaso")[0];
      } else {
        return this.item.nome.split("Basket")[0];
      }
    },
    exist() {
      return this.$store.getters.get_lista_carrello.find(
        (el) => el.art == this.item.id
      );
    },
    info_icon() {
      let exposition;
      switch (this.item.exposition) {
        case 1:
          exposition = { icon: "mdi-weather-sunny", color: "amber", text: "Pieno Sole" };
          break;
        case 2:
          exposition = { icon: "mdi-weather-partly-cloudy", color: "purple", text: "Mezza ombra" };
          break;
        case 3:
          exposition = { icon: "mdi-weather-cloudy", color: "grey", text: "Piena Ombra" };
          break;
        default:
          exposition = { icon: "", color: "grey" };
          break;
      }
      let water;
      switch (this.item.water_request) {
        case 1:
          water = { icon: "mdi-water-minus", color: "lime", text: "Poca Acqua" };
          break;
        case 2:
          water = { icon: "mdi-water", color: "cyan", text: "Acqua Media" };
          break;
        case 3:
          water = { icon: "mdi-water-plus", color: "indigo", text: "Molta Acqua" };
          break;
        default:
          water = { icon: "", color: "grey" };
          break;
      }
      let frost
      switch (this.item.frost_resist) {
        case 2:
          frost = { icon: "mdi-snowflake", color: "blue", text: "Resistente al Gelo" };
          break;
        case 1:
          frost = { icon: "mdi-snowflake-off", color: "orange", text: "Non Resistente al Gelo" };
          break;
        default:
          frost = { icon: "", color: "grey" };
          break;
      }
      return {
        exposition: exposition,
        water: water,
        frost: frost
      };
    },
  },

  watch: {
    // commento: function () {
    //   this.debouncecmt();
    // },
    $route(to, from) {
      if (from.hash === "#dettaglio-prodotto" && this.dialog) {
        this.dialog = false;
      }
    },
  },

  methods: {
    invia_cmt() {
      this.$store.dispatch("invio_commento", {
        id_prodotto: this.item.id,
        commento: this.cmt_to_send,
      });
    },
    open() {
      this.dialog = true;
      this.$router.push("#dettaglio-prodotto");
    },
    close() {
      this.dialog = false;
      this.$router.back();
    },
    switch_pref() {
      this.item.preferito = !this.item.preferito;
      this.$store.dispatch("switch_pref", this.item);
    },
  },
};
